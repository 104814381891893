.course-plan-wrapper {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 0.3rem;
  grid-area: course-plan;

  .weekday-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    background-color: #29013f;
    color: white;
    font-size: 1.25rem;
  }

  .course-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: whitesmoke;
    padding: 1rem 0.7rem;
    height: 100%;

    .course-name,
    .course-time,
    .course-trainer {
      margin: 0;
    }

    .course-name {
      font-weight: bold;
      text-transform: uppercase;
      margin: 0.3rem 0;
    }

    .course-time,
    .course-trainer {
      color: #525252;
    }

    &.active {
      background-color: #ffe500;
      color: #000000;

      .course-trainer {
        color: #474747;
      }
    }
  }
}
