.app-wrapper {
  height: 100vh;
  padding: 0.5rem;
  display: grid;
  grid-template-areas:
    'course-plan course-plan course-plan course-plan course-plan course-plan course-plan'
    'time-code news-header news news news news news';
  grid-template-rows: 85% 15%;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 0.2rem;
  letter-spacing: 2px;
}
