@font-face {
  font-family: "Avenir Book";
  font-display: auto;
  src: local("AvenirLTW04-45Book"),
    url("./assets/fonts/AvenirLTW04-45Book.woff") format("woff");
}

html {
  margin: 0;
  box-sizing: border-box;
  font-family: "Avenir Book";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
}

.hidden {
  display: none !important;
}
